/* -------------------------------------------------------------------------- */
/*                                    Fonts                                   */
/* -------------------------------------------------------------------------- */

@font-face {
  font-family: 'helvetica';
  src: url('../../public/fonts/helvetica/Helvetica.ttf');
}

$helvetica: 'helvetica';

@font-face {
  font-family: 'helvetica-light-regular';
  src: url('../../public/fonts/helvetica-light/Helvetica\ Light\ Regular.otf');
}

$helvetica-light-regular: 'helvetica-light-regular';

:export {
  crFontPrimary: $helvetica;
  crFontSecondary: $helvetica-light-regular;
}

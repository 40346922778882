/* -------------------------------------------------------------------------- */
/*                                   Modules                                  */
/* -------------------------------------------------------------------------- */

// Colors
@use '../../../../styles/_colors.scss';

/* -------------------------------------------------------------------------- */
/*                          Mobile & Tablet & Desktop                         */
/* -------------------------------------------------------------------------- */

.loading-overlay {
  > svg {
    /* !important is used to override mantine ui default svg stroke */
    stroke: colors.$cr-brown-1 !important;
  }
}
